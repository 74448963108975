import React from "react";
import Container from "./Container";

import "normalize.css";
import "../styles/global.css";
import "../styles/print.css";

export default function Layout({ children }) {
  return (
    <div css={styles.root}>
      <div css={styles.header}>
        <Container wide></Container>
      </div>
      <main css={{ flex: 1 }} role="main">
        <Container>{children}</Container>
      </main>
    </div>
  );
}

const styles = {
  root: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",

    "@media print": {
      backgroundColor: "green",
    },
  },

  header: {
    marginBottom: "3rem",
    borderBottom: "1px solid #eee",

    "@media print": {
      display: "none",
    },
  },

  footer: {
    marginTop: "4rem",

    "@media print": {
      display: "none",
    },
  },
};
